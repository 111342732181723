import { Routes, Route } from "react-router-dom";
import NotFound from "./components/pages/404";
import DataEntry from "./components/pages/DataEntry";
import { useEffect, useState } from "react";
import Layout from "./components/Layout";
import Responses from "./components/pages/Responses";
import { Toaster } from "react-hot-toast";
import Login from "./components/auth/Login";
import axios from "axios";
import Admin from "./components/admin/Admin";
import { Log } from "./components/functions/Log";
import { toastEffect } from "./components/ToastEffect";

function App() {

  const [accountData, setAccountData] = useState([]);

  // Access token
  const token = JSON.parse(localStorage.getItem("account"));

  useEffect(() => {
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      await axios.post(`${process.env.REACT_APP_SERVER}checkSession`, {
        token: token?.accessToken
      }).then(async (response) => {
        // console.log(response.data);
        setAccountData(response.data);
      }).catch((err) => {
        // console.log(JSON.stringify(err));
        // console.log(err);
        if (err?.response?.data?.msg) {
          if (err?.response?.data.msg === "Token expired") {
            localStorage.removeItem("account");
            Log(token, "Timeout");
            toastEffect("error", "Session timeout");
          } else {
            localStorage.removeItem("account");
          }
        } else {
          localStorage.removeItem("account");
        }
      });
    };
    checkSession();
  }, []);

  return (
    <>
      <Toaster position="buttom-right" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<Layout accountData={accountData} />} >
          <Route path="/" element={<Login />} />
          <Route path="/data-entry" element={<DataEntry accountData={accountData} />} />
          <Route path="/responses" element={<Responses accountData={accountData} />} />
          {accountData.role === 3 && (
            <Route path="/admin" element={<Admin accountData={accountData} />} />
          )}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
