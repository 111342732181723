import Swal from "sweetalert2";
import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";

export const HandleRemoveResponse = (
  setIsResponseMenuOpen,
  responses,
  setResponses,
  client,
  responseID,
  accountData
) => {

  if (!accountData?.flast_id) { return; }

  setIsResponseMenuOpen(Array(responses.length).fill(false));

  let color = "";

  if (localStorage.theme === "dark") {
    color = "#111827";
  } else {
    color = "";
  }

  Swal.fire({
    background: color,
    html:
      `
        <label class= "text-gray-800 dark:text-gray-100 font-semibold text-2xl">Remove Response</label>
        <form class="border p-5 rounded-lg mt-3 border-red-500">
          <p class="text-gray-800 dark:text-gray-100">This action will remove this response permanenty.</p>
        </form>
      `,
    showCancelButton: true,
    confirmButtonText: 'Remove',
    showLoaderOnConfirm: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'bg-red-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-red-500 w-36',
      cancelButton: 'bg-blue-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-blue-500 w-20'
    },
    preConfirm: async () => {
      await Record(client, accountData, "Response", responseID, "remove");
      await client.data
        .deleter()
        .withClassName('Response')
        .withId(responseID)
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.QUORUM)
        .do()
        .then(res => {
          const filteredData = responses.filter(post => post.id !== responseID);
          setResponses(filteredData);
          toastEffect("success", "Succesfully removed!");
        })
        .catch(err => {
          console.error(err);
        });
    },
    allowOutsideClick: () => !Swal.isLoading()
  });
};