import weaviate from "weaviate-client";

export const HandleAccountStatus = async (
  e,
  AccountID,
  client,
  accountData,
  setRefreshData
) => {
  if (!accountData?.flast_id) { return; }

  let status = "";

  if (e.target.checked) {
    status = "active";
  } else {
    status = "disabled";
  }

  await client.data
    .getterById()
    .withClassName("Accounts")
    .withId(AccountID)
    .do()
    .then(async res => {
      // alter the schema
      const schema = res.properties;
      schema.status = status;
      await client.data
        .updater()
        .withId(AccountID)
        .withClassName("Accounts")
        .withProperties(schema)
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
        .do();
    }).then(res => {
      setRefreshData(old => old + 1);
    }).catch(err => {
      console.error(err);
    });
};