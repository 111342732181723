import weaviate from "weaviate-client";

export const Log = async (token, reason) => {

  // Weaviate connection config
  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  await client.data
    .creator()
    .withClassName('Logs')
    .withProperties({
      'employee_name': token?.data1,
      'employee_email': token?.data2,
      'reason': reason,
    })
    .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
    .do()
    .then(res => {
      // console.log(res);
      if (reason === "Login") {
        window.location.href = "/data-entry";
      } else {
        window.location.href = "/";
      }
    })
    .catch(err => {
      console.error(err);
    });
};