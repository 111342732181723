import Swal from "sweetalert2";
import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";

export const HandleUpdateComment = (
  setIsMenuOpen,
  comments,
  client,
  setRefreshData,
  facebookName,
  facebookProfile,
  facebookComment,
  CommentID,
  accountData
) => {

  if (!accountData?.flast_id) { return; }

  setIsMenuOpen(Array(comments.length).fill(false));

  let color = "";
  let textColor = "";

  if (localStorage.theme === "dark") {
    color = "#111827";
    textColor = "gainsboro";
  } else {
    color = "";
    textColor = "#141313";
  }

  Swal.fire({
    background: color,
    input: 'textarea',
    inputLabel: 'Update POST',
    inputValue: facebookComment,
    inputPlaceholder: 'Type your message here...',
    inputAttributes: {
      'aria-label': 'Type your message here',
      'style': `color: ${textColor}; height: 300px;`,
    },
    showCancelButton: true,
    confirmButtonText: 'Update',
    showLoaderOnConfirm: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'bg-green-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-green-500 w-36',
      cancelButton: 'bg-blue-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-blue-500 w-20',
      inputLabel: 'text-gray-700 dark:text-gray-100 text-3xl font-semibold',
      popup: 'my-modal-class',
    },
    preConfirm: async () => {
      const updatedComment = document.querySelector('.swal2-textarea').value;
      await client.data
        .getterById()
        .withClassName("Comment")
        .withId(CommentID)
        .do()
        .then(async res => {
          // console.log(res);
          // alter the schema
          const schema = res.properties;
          schema.fb_comment = updatedComment;
          await client.data
            .updater()
            .withId(CommentID)
            .withClassName("Comment")
            .withProperties(schema)
            .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
            .do()
            .then(async res => {
              // console.log(res);
              await Record(client, accountData, res.class, res.id, "update");
            });
        }).catch(err => {
          console.error(err);
        });
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      setRefreshData(old => old + 1);
      toastEffect("success", "Saved succesfully!");
    }
  });
};