// POST Object
let classObj_POST = {
  'class': "POST",
  'description': 'All login and logout records',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook post name',
      'name': 'fb_name',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook post profile',
      'name': 'fb_profile',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook post',
      'name': 'fb_post',
      'vectorizePropertyName': true,
      'index': true
    }
  ]
};

// Post Object Additional property
let classObj_POST_additional_prop_writesfor = {
  dataType: ['Persons'],
  name: 'writesFor',
};
let classObj_POST_additional_prop_linkedto = {
  dataType: ['Comment'],
  name: 'LinkedTo'
};

// Comment Object
let classObj_Comment = {
  'class': "Comment",
  'description': 'All login and logout records',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook comment name',
      'name': 'fb_name',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook comment profile',
      'name': 'fb_profile',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook comment',
      'name': 'fb_comment',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'POST',
      ],
      'description': 'facebook comment writes for post',
      'name': 'writesFor',
      'vectorizePropertyName': true,
      'index': true
    }
  ]
};

// Comment Object Additional property
let classObj_Comment_additional_prop = {
  dataType: ['Response'],
  description: 'facebook comment linked to response',
  name: 'LinkedTo',
  vectorizePropertyName: true,
  index: true
};

// Response Object
let classObj_Respose = {
  'class': "Response",
  'description': 'All login and logout records',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook response name',
      'name': 'fb_name',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook response profile',
      'name': 'fb_profile',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'facebook response',
      'name': 'fb_response',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'Comment'
      ],
      'description': 'facebook comment writes for Comment',
      'name': 'writesFor',
      'vectorizePropertyName': true,
      'index': true
    }
  ]
};

// Accounts Object
let classObj_Accounts = {
  'class': "Accounts",
  'description': 'All login and logout records',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'int'
      ],
      'description': 'flast database ID',
      'name': 'flast_id',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'int'
      ],
      'description': 'flast profile ID',
      'name': 'profile_id',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'flast email address',
      'name': 'email',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'int'
      ],
      'description': 'flast account role if 3 admin else user',
      'name': 'role',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'account status',
      'name': 'status',
      'vectorizePropertyName': true,
      'index': true
    },
  ]
};

// Records Object
let classObj_Records = {
  'class': "Records",
  'description': 'All login and logout records',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'string'
      ],
      'description': 'employee name',
      'name': 'employee_name',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'employee email',
      'name': 'employee_email',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'what kind of data executed',
      'name': 'data_kind',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'what kind of action made',
      'name': 'action_type',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'POST',
        'Comment',
        'Response'

      ],
      'description': 'data id that is saved via beacon',
      'name': 'dat_id',
      'vectorizePropertyName': true,
      'index': true
    },
  ]
};

// Logs Object
let classObj_Logs = {
  'class': "Logs",
  'description': 'All login and logout records',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'string'
      ],
      'description': 'Employee name',
      'name': 'employee_name',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'Employee email',
      'name': 'employee_email',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'Action reason',
      'name': 'reason',
      'vectorizePropertyName': true,
      'index': true
    }
  ]
};

// Persons Object
let classObj_Persons = {
  'class': "Persons",
  'description': 'All persons are save in this class',
  'vectorizeClassName': true,
  'properties': [
    {
      'dataType': [
        'string'
      ],
      'description': 'persons facebook name',
      'name': 'fb_name',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'string'
      ],
      'description': 'persons facebook profile',
      'name': 'fb_profile',
      'vectorizePropertyName': true,
      'index': true
    },
    {
      'dataType': [
        'POST',
        'Comment',
        'Response'
      ],
      'description': 'This person is linked to these classes',
      'name': 'LinkedTo',
      'vectorizePropertyName': true,
      'index': true
    },
  ]
};

export const classObjectsArray = [
  classObj_POST, // 0
  classObj_Respose, // 1
  classObj_Accounts, // 2
  classObj_Comment, // 3
  classObj_Records, // 4
  classObj_Logs, // 5
  classObj_Persons, // 6
  classObj_POST_additional_prop_writesfor, // 7 additional prop
  classObj_POST_additional_prop_linkedto, // 8 additional prop
  classObj_Comment_additional_prop // 9 additional prop
];
