import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import moment from "moment";
import { HandleAccountStatus } from "../../functions/HandleAccountStatus";
import { HandleRemoveAccount } from "../../functions/HandleRemoveAccount";
import { Retriever } from "../../functions/Retriever ";


const Accounts = (props) => {
  const {
    client,
    accountData
  } = props;

  moment.locale('en');

  const [searchPost, setSearchPost] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [refreshData, setRefreshData] = useState(0);

  // useEffect(() => {
  //   if (!accountData?.flast_id) { return; }
  //   client.data
  //     .getter()
  //     .withClassName('Accounts')
  //     .do()
  //     .then(res => {
  //       // console.log("Accounts", res);
  //       setAccounts(res.objects);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // }, [refreshData]);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }
    const resPromise = Retriever("Accounts", 100);
    resPromise.then(res => {
      setAccounts(res);// This will log the resolved value of the Promise
    }).catch(err => {
      console.error(err); // Handle errors here
    });
  }, [refreshData]);

  // filtered for search and sorted by time
  const accountSearch = accounts
    .sort((b, a) => a.creationTimeUnix - b.creationTimeUnix)
    .filter((data) => {
      if (searchPost === "") {
        return data;
      } else {
        return (
          data.properties.email.toLowerCase().includes(searchPost.toLowerCase()) ||
          data.properties.role.toLowerCase().includes(searchPost.toLowerCase()) ||
          data.properties.status.toLowerCase().includes(searchPost.toLowerCase()) ||
          data.properties.flast_id.toLowerCase().includes(searchPost.toLowerCase())
        );
      }
    });

  function statusChecker(status) {
    if (status === "active") {
      return 1;
    } else {
      return 0;
    }
  }

  return (
    <>
      <form className="mb-5">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          {/* <div className="flex items-center lg:order-2 mb-3 lg:mb-0">
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              From-<ReactDatePicker className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" selected={fromDate} onChange={(date) => setFromDate(date)} showTimeSelect dateFormat="Pp" />
            </div>
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              To-<ReactDatePicker className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" selected={toDate} onChange={(date) => setToDate(date)} showTimeSelect dateFormat="Pp" />
            </div>
          </div> */}
          <div className="flex items-center lg:order-2 mb-3 lg:mb-0">
            <div title="Registered accounts" className="cursor-help inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              {accounts?.length}
            </div>
          </div>
          <div className="relative lg:order-1 w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
            </div>
            <input onChange={(e) => setSearchPost(e.target.value)} type="text" placeholder="Search for accounts" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          </div>
        </div>
      </form>



      <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[45rem] scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md">

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 text-center">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Action
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-end">
                Registered
              </th>
              <th scope="col" className="px-6 py-3 float-right">
                Remove
              </th>
            </tr>
          </thead>
          <tbody>

            {accountSearch?.map((data, idx) => {

              return (
                <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" >
                  <th className="text-center">
                    {data.properties.flast_id}
                  </th>
                  <th className="px-6 py-4 font-medium text-gray-900 dark:text-white" >
                    {data.properties.email}
                  </th>
                  <td className="px-6 py-4">
                    <p className="cursor-help text-gray-600 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-100">
                      {data.properties.role !== 3 ? "User" : "Admin"}
                    </p>
                  </td>
                  <td className="w-4 p-4 text-center">
                    <label title="Toggle to enable or disable" className="relative inline-flex items-center cursor-pointer">
                      <input
                        onChange={
                          (e) => HandleAccountStatus(
                            e,
                            data.id,
                            client,
                            accountData,
                            setRefreshData
                          )
                        }
                        defaultChecked={statusChecker(data.properties.status)}
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <p className={`${data.properties.status === "active" ? "text-green-400" : "text-red-400"} font-semibold`}>
                      {data.properties.status}
                    </p>
                  </td>
                  <td className="px-6 py-4  text-end">
                    <p title={moment(data.creationTimeUnix).format("MMMM Do YYYY, h:mm:ss a")} className="cursor-help" >
                      {moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow()}
                    </p>
                  </td>
                  <td className="px-6 py-4 float-right">
                    <FontAwesomeIcon
                      onClick={
                        () => HandleRemoveAccount(
                          data.id,
                          client,
                          accountData,
                          setRefreshData
                        )
                      }
                      icon={faTrash}
                      title="Delete account"
                      className="cursor-pointer ml-1 text-red-500 hover:text-red-600"
                    />
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
    </>
  );
};

export default Accounts;