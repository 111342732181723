export const Retriever = async (className, limit) => {
  const server = `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_WEAVIATE_ENPOINT}`;
  const interval = 100;
  let after = "";
  let retrieved = 0;
  const allObjects = [];

  while (true) {
    const res = await fetch(`${server}/v1/objects?class=${className}&limit=${limit}&after=${after}`);
    const text = await res.text();

    // Parse response as JSON
    const data = JSON.parse(text);
    const objects = data.objects;

    // If no objects were returned, we're done
    if (objects.length === 0) {
      // console.log(allObjects);
      break;
    }

    // Add the new objects to the existing array
    allObjects.push(...objects);

    // Otherwise, update `after` to the ID of the last object in the result set
    retrieved += objects.length;
    after = objects[objects.length - 1].id;

    // // Print progress
    // if (retrieved % interval === 0) {
    //   console.log(`retrieved ${interval} objects - latest id is ${after}`);
    // }
  }

  // console.log(`REST retrieval complete! Retrieved ${retrieved} objects via /v1/objects REST endpoint`);
  return allObjects;
};
