import axios from "axios";
import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Log } from "./Log";
import { classObjectsArray } from "./ClassObjects";

export const HandleLoginAccount = async (
  e,
  rememberMe,
  setLoggingIn
) => {

  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  e.preventDefault();
  e.stopPropagation();

  const email = e.target.elements.email.value;
  const password = e.target.elements.password.value;

  if (rememberMe) {
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
  } else {
    localStorage.removeItem("email");
    localStorage.removeItem("password");
  }

  if (email === "" || password === "") {
    return toastEffect("error", "Fields are required.");
  }

  const _grant_type = "flast_request";
  const _client_id = "gio101919_id";
  const _client_secret = "gio101919_secret";

  setLoggingIn(true);

  await axios.post(`${process.env.REACT_APP_API_URL}token`, {
    grant_type: _grant_type,
    client_id: _client_id,
    client_secret: _client_secret,
  }).then(async (response) => {
    await axios.post(`${process.env.REACT_APP_API_URL}user`, {
      accesstoken: response.data.accessToken,
      email: email,
      password: password,
    }).then(async (response) => {

      // console.log(response.data);

      await client.data
        .getter()
        .withClassName('Accounts')
        .do()
        .then(async res => {
          // console.log("Accounts", res);
          // console.log("Accounts", JSON.stringify(res));
          // const emailExists = res.objects.find(obj => obj.properties.email === response.data[0].email) !== undefined;
          const result = res.objects.find(obj => obj.properties.email === response.data[0].email);
          const emailExists = { exists: !!result, data: result };
          // console.log(emailExists);
          if (!emailExists.exists) {
            await client.data
              .creator()
              .withClassName('Accounts')
              .withProperties({
                'flast_id': response.data[0].id,
                'profile_id': response.data[0].profile_id,
                'email': response.data[0].email,
                'role': response.data[0].role,
                'status': 'disabled',
              })
              .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
              .do()
              .then(async res => {
                setLoggingIn(false);
                toastEffect("error", "Invalid account or password");
                // await axios.post(`${process.env.REACT_APP_SERVER}authenticate`, {
                //   email: response.data[0].email,
                //   role: response.data[0].role,
                //   flast_id: response.data[0].id,
                //   profile_id: response.data[0].profile_id,
                //   status: 'active',
                //   name: response.data[0].name
                // }).then(async (response) => {
                //   localStorage.setItem("account", JSON.stringify(response.data));
                //   localStorage.setItem("selectedPage", "dataEntry");
                //   // window.location.href = "/data-entry";                  
                //   await Log(response.data, "Login");
                // }).catch((err) => {
                //   toastEffect("error", "There is a server error.");
                // });
              })
              .catch(err => {
                console.error(err);
              });
          } else {
            if (emailExists.data.properties.status === "active") {
              await axios.post(`${process.env.REACT_APP_SERVER}authenticate`, {
                email: emailExists.data.properties.email,
                role: emailExists.data.properties.role,
                flast_id: emailExists.data.properties.flast_id,
                profile_id: emailExists.data.properties.profile_id,
                status: emailExists.data.properties.status,
                name: response.data[0].name
              }).then(async (response) => {
                localStorage.setItem("account", JSON.stringify(response.data));
                localStorage.setItem("selectedPage", "dataEntry");
                // window.location.href = "/data-entry";
                await Log(response.data, "Login");
              }).catch((err) => {
                toastEffect("error", "There is a server error.");
              });
            } else {
              setLoggingIn(false);
              toastEffect("error", "Invalid account or password");
            }

          }
        })
        .catch(err => {
          // Create Object
          client.schema
            .classCreator()
            .withClass(classObjectsArray[2])
            .do()
            .then(res => {
              window.location.reload();
            })
            .catch(err => {
              console.error(err);
            });
          console.error(err);
        });


    }).catch((err) => {
      if (err.response?.data) {
        toastEffect("error", err.response.data.msg);
      } else {
        console.error(err);
      }
      setLoggingIn(false);
    });
  }).catch((err) => {
    if (err?.response?.data) {
      toastEffect("error", err.response.data.msg || err.response.data);
    } else {
      console.error(err);
    }
  });

};