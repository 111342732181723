import LeftSide from "../pages/LeftSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faClipboard, faFilePen, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Records from "./pages/Records";
import weaviate from "weaviate-client";
import Accounts from "./pages/Accounts";
import Logs from "./pages/Logs";
import { HandleSchemaCreate } from "../functions/HandleSchemaCreate";
import { HandleSchemaDelete } from "../functions/HandleSchemaDelete";

const Admin = ({ accountData }) => {

  const [selectedTab, setSelectedTab] = useState("records");
  const [creatingSchema, setCreatingSchema] = useState(false);
  const [deletingSchema, setDeletingSchema] = useState(false);

  // Selected tab from local storage
  const localSelectedTab = localStorage.getItem("selectedTab");

  // Weaviate connection config
  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  const handleSelectedTab = (tab) => {
    localStorage.setItem("selectedTab", tab);
    setSelectedTab(tab);
  };

  // // Get all schema
  // useEffect(() => {
  //   client.schema
  //     .getter()
  //     .do()
  //     .then(res => {
  //       console.log("Schema", res);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // }, []);

  return (
    <>
      <LeftSide accountData={accountData} />
      <div className="p-4 sm:ml-64 h-screen">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <div className="grid lg:grid-cols-1 gap-4 mb-4">

            {/* <form className="border-gray-400 dark:border-gray-600 p-3 border rounded-md">
              <label className="text-gray-600 dark:text-gray-200 font-bold">Add new post</label>
              <div className="grid md:grid-cols-2 md:gap-6 mt-3">
                <div className="relative z-0 w-full mb-6 group">
                  <input type="text" name="facebook_post_name" id="facebook_post_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="facebook_post_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input type="text" name="facebook_post_profile" id="facebook_post_profile" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="facebook_post_profile" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Profile
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <textarea
                  rows="1"
                  type="text"
                  name="facebook_post"
                  id="facebook_post"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required />
                <label htmlFor="facebook_post" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  Facebook Post
                </label>
              </div>
              <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <FontAwesomeIcon icon={faArrowsRotate} size="1x" /> Save Post
              </button>
            </form> */}

            {/* <form className="border-gray-400 dark:border-gray-600 p-3 border rounded-md" onSubmit={handleComments}>
              <label className="text-gray-600 dark:text-gray-200 font-bold">Add new comment</label>
              <div className="grid md:grid-cols-2 md:gap-6 mt-3">
                <div className="relative z-0 w-full mb-6 group">
                  <input type="text" name="facebook_comment_name" id="facebook_comment_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="facebook_comment_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input type="text" name="facebook_comment_profile" id="facebook_comment_profile" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="facebook_comment_profile" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Profile
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <textarea
                  ref={textAreaComment}
                  onChange={(e) => setCommentValue(e.target.value)}
                  rows="1"
                  type="text"
                  name="facebook_comment"
                  id="facebook_comment"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required />
                <label htmlFor="facebook_comment" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  Facebook Comment
                </label>
              </div>
              <button disabled={savingComment ? true : false} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <FontAwesomeIcon spin={savingComment ? true : false} icon={faArrowsRotate} size="1x" /> {savingComment ? "Saving..." : "Save Comment"}
              </button>
            </form> */}


            <div className="border-b border-gray-200 dark:border-gray-700">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">

                {/* inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group */}

                <li className="mr-2">
                  <h1 onClick={() => handleSelectedTab("records")} className={`${selectedTab === "records" ? "border-blue-600 text-blue-600" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"} cursor-pointer inline-flex p-4 border-b-2 rounded-t-lg`}>
                    <FontAwesomeIcon icon={faClipboard} className="w-4 h-4 mr-2" />
                    Records
                  </h1>
                </li>
                <li className="mr-2">
                  <h1 onClick={() => handleSelectedTab("users")} className={`${selectedTab === "users" ? "border-blue-600 text-blue-600" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"} cursor-pointer inline-flex p-4 border-b-2 rounded-t-lg`}>
                    <FontAwesomeIcon icon={faUsers} className="w-4 h-4 mr-2" />
                    Accounts
                  </h1>
                </li>
                <li className="mr-2">
                  <h1 onClick={() => handleSelectedTab("logs")} className={`${selectedTab === "logs" ? "border-blue-600 text-blue-600" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"} cursor-pointer inline-flex p-4 border-b-2 rounded-t-lg`}>
                    <FontAwesomeIcon icon={faFilePen} className="w-4 h-4 mr-2" />
                    Logs
                  </h1>
                </li>
                <li className="ml-auto">
                  <button title="Create all required Schema" disabled={creatingSchema ? true : false} onClick={() => HandleSchemaCreate(setCreatingSchema)} type="submit" className="mr-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <FontAwesomeIcon spin={creatingSchema ? true : false} icon={faArrowsRotate} size="1x" /> {creatingSchema ? "Creating..." : "Create"}
                  </button>
                  <button title="Delete all Schema" disabled={deletingSchema ? true : false} onClick={() => HandleSchemaDelete(setDeletingSchema)} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <FontAwesomeIcon spin={deletingSchema ? true : false} icon={faArrowsRotate} size="1x" /> {deletingSchema ? "Deleting..." : "Delete"}
                  </button>
                </li>
              </ul>
            </div>


          </div>
          {selectedTab === "users" ? (
            <Accounts
              client={client}
              accountData={accountData}
            />
          ) : selectedTab === "logs" ? (
            <Logs
              client={client}
              accountData={accountData}
            />
          ) : (
            <Records
              client={client}
              accountData={accountData}
            />
          )}


        </div>
      </div>
    </>
  );
};

export default Admin;