import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faRightFromBracket, faUserSecret, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const LeftSide = ({ accountData }) => {

  const [showNote, setShowNote] = useState(true);
  // const [selectedPage, setSelectedPage] = useState("dataEntry");

  const selectedPage = localStorage.getItem("selectedPage");

  const handleSelectedPage = (page) => {
    localStorage.setItem("selectedPage", page);
    // setSelectedPage(page);
  };

  const handleCloseNote = () => {
    setShowNote(false);
  };

  return (
    <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-gray-50 border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
      <div className="h-full px-3 pb-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
        <div className="h-full flex flex-col justify-between">
          <ul className="space-y-2 font-medium">
            <li>
              <Link onClick={() => handleSelectedPage("dataEntry")} to="/data-entry" className={`${selectedPage === "dataEntry" && "bg-gray-300 dark:bg-gray-700"} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700`}>
                <FontAwesomeIcon icon={faDashboard} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ml-3">Data Entry</span>
              </Link>
            </li>
            {accountData?.role === 3 && (
              <li>
                <Link onClick={() => handleSelectedPage("admin")} to="/admin" className={`${selectedPage === "admin" && "bg-gray-300 dark:bg-gray-700"} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700`}>
                  <FontAwesomeIcon icon={faUserSecret} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className="flex-1 ml-3 whitespace-nowrap">Administrator</span>
                  {/* <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> */}
                </Link>
              </li>
            )}

            {/* <li>
              <a href="#" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Inbox</span>
                <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>
              </a>
            </li> */}
          </ul>
          <ul className="space-y-2 font-medium">
            {/* <li className="border-t border-gray-300 border-opacity-50 mt-3">
              <Link to="#" className="flex items-center mt-3 p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700">
                <FontAwesomeIcon icon={faRightFromBracket} className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="flex-1 ml-3 whitespace-nowrap">Log Out</span>
              </Link>
            </li> */}
            {showNote && (
              <div id="dropdown-cta" className="p-4 mt-6 rounded-lg bg-blue-50 dark:bg-blue-900" role="alert">
                <div className="flex items-center mb-3">
                  <span className="bg-orange-100 text-orange-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">
                    v2.0.3
                  </span>
                  <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-900 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 hover:bg-blue-200 inline-flex h-6 w-6 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800" data-dismiss-target="#dropdown-cta" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon onClick={handleCloseNote} className="w-4 h-4" icon={faXmark} />
                  </button>
                </div>
                <p className="mb-3 text-sm text-blue-800 dark:text-blue-400">
                  This app is newly developed and still in beta. If you encounter any bugs, please don't hesitate to report them.
                </p>
                <Link to="https://flast.com.au/" target="_blank" className="text-sm text-blue-800 underline font-medium hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300" >
                  flast.com.au
                </Link>
              </div>
            )}

          </ul>
        </div>
      </div>
    </aside>
  );
};

export default LeftSide;