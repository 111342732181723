import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './pages/Header';
import LeftSide from './pages/LeftSide';

const Layout = ({ accountData }) => {
  return (
    <div className='dark:bg-gray-900 h-auto'>
      <Header accountData={accountData} />
      {/* <LeftSide /> */}
      <Outlet />
    </div>
  );
};

export default Layout;