import Swal from "sweetalert2";
import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";

export const HandleRemoveComment = (
  setIsMenuOpen,
  comments,
  client,
  setComments,
  commentID,
  responseCount,
  accountData
) => {

  if (!accountData?.flast_id) { return; }

  setIsMenuOpen(Array(comments.length).fill(false));

  let color = "";

  if (localStorage.theme === "dark") {
    color = "#111827";
  } else {
    color = "";
  }

  Swal.fire({
    background: color,
    html:
      `
        <label class= "text-gray-800 dark:text-gray-100 font-semibold text-2xl">Remove Comment</label>
        <form class="border p-5 rounded-lg mt-3 border-red-500">
          <p class="text-gray-800 dark:text-gray-100">This action will remove this comment permanenty.</p>
        </form>
      `,
    showCancelButton: true,
    confirmButtonText: 'Remove',
    showLoaderOnConfirm: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'bg-red-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-red-500 w-36',
      cancelButton: 'bg-blue-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-blue-500 w-20'
    },
    preConfirm: async () => {
      if (responseCount > 0) {
        return toastEffect("error", `Please remove ${responseCount > 1 ? "responses" : "response"} first!`);
      } else {
        await Record(client, accountData, "Comment", commentID, "remove");
        await client.data
          .deleter()
          .withClassName('Comment')
          .withId(commentID)
          .withConsistencyLevel(weaviate.replication.ConsistencyLevel.QUORUM)
          .do()
          .then(res => {
            const filteredData = comments.filter(post => post.id !== commentID);
            setComments(filteredData);
            toastEffect("success", "Succesfully removed!");
          })
          .catch(err => {
            console.error(err);
          });
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  });
};