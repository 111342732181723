import Swal from "sweetalert2";
import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";

export const HandleUpdatePerson = (
  personID,
  client,
  setRefreshData,
  accountData,
  fb_name,
  fb_profile
) => {
  if (!accountData?.flast_id) { return; }

  let color = "";
  let textColor = "";

  if (localStorage.theme === "dark") {
    color = "#111827";
    textColor = "gainsboro";
  } else {
    color = "";
    textColor = "#141313";
  }

  Swal.fire({
    background: color,
    title: 'Update Person',
    html:
      `<input value="${fb_name}" id="swal-input1" placeholder="Facebook name" class="swal2-input text-gray-700 dark:text-gray-100 font-semibold">` +
      `<input value="${fb_profile}" id="swal-input2" placeholder="Facebook Profile" class="swal2-input text-gray-700 dark:text-gray-100 font-semibold">`,
    focusConfirm: false,
    showCancelButton: true,
    confirmButtonText: 'Update',
    showLoaderOnConfirm: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'bg-green-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-green-500 w-36',
      cancelButton: 'bg-blue-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-blue-500 w-20',
      title: 'text-gray-700 dark:text-gray-100 text-3xl font-semibold',
    },
    preConfirm: async () => {
      const fb_name = document.getElementById('swal-input1').value;
      const fb_profile = document.getElementById('swal-input2').value;
      await client.data
        .getterById()
        .withClassName("Persons")
        .withId(personID)
        .do()
        .then(async res => {
          await Record(client, accountData, res.class, res.id, "update");
          // alter the schema
          const schema = res.properties;
          schema.fb_name = fb_name;
          schema.fb_profile = fb_profile;
          await client.data
            .updater()
            .withId(personID)
            .withClassName("Persons")
            .withProperties(schema)
            .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
            .do();
        }).then(res => {
          // console.log(res);
        }).catch(err => {
          console.error(err);
        });
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      setRefreshData(old => old + 1);
      toastEffect("success", "Updated succesfully!");
    }
  });
};