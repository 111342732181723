import weaviate from "weaviate-client";
import { toastEffect } from "../ToastEffect";
import { Record } from "./Record";
import { UpdatePerson } from "./UpdatePerson";
import { UpdatePOST } from "./UpdatePOST";
import { Retriever } from "./Retriever ";

export const HandleComment = async (
  e,
  client,
  setSavingComment,
  postID,
  setComments,
  setCommentValue,
  setCommentName,
  setCommentProfile,
  accountData,
  setPersonData
) => {

  e.preventDefault();
  e.stopPropagation();

  if (!accountData?.flast_id) { return; }

  // const fb_name = e.target.elements.facebook_comment_name.value;
  // const fb_profile = e.target.elements.facebook_comment_profile.value;
  const fb_comment = e.target.elements.facebook_comment.value;
  const fb_person = e.target.elements.facebook_post_person.value;

  const name = fb_person.split(' - ')[0];
  const fbLink = fb_person.split(' - ')[1];

  if (fb_comment === "" || fb_person === "") {
    return;
  }

  setSavingComment(true);

  // await client.data
  //   .getter()
  //   .withClassName('Persons')
  //   .do()
  //   .then(async res => {
  const resPromise = Retriever("Persons", 100);
  resPromise.then(async res => {

    const result = res.find(obj => obj.properties.fb_profile === fbLink);
    const profileIDExist = { exists: !!result, data: result };
    // console.log(profileIDExist);
    if (profileIDExist.exists) {
      await client.data
        .creator()
        .withClassName('Comment')
        .withProperties({
          'fb_name': name,
          'fb_profile': fbLink,
          'fb_comment': fb_comment,
          'writesFor': [{
            beacon: `weaviate://localhost/POST/${postID}`,
            href: `/v1/objects/POST/${postID}`
          }]
        })
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
        .do()
        .then(async res => {
          // console.log(res);
          await Record(client, accountData, res.class, res.id, "insert");
          await UpdatePOST(client, res.class, res.id, postID);
          await UpdatePerson(client, res.class, res.id, profileIDExist.data.id);

          const newComment = {
            class: "Comment",
            creationTimeUnix: Date.now(),
            id: res.id,
            lastUpdateTimeUnix: Date.now(),
            properties: {
              fb_name: name,
              fb_profile: fbLink,
              fb_comment: fb_comment,
              writesFor: [{
                beacon: `weaviate://localhost/POST/${postID}`,
                href: `/v1/objects/POST/${postID}`
              }]
            },
            vectorWeights: null
          };

          setComments(prevData => [...prevData, newComment]);
          setCommentValue("");
          setPersonData("");
          setSavingComment(false);
          toastEffect("success", "Comment added successfully.");
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      setSavingComment(false);
      toastEffect("error", "Invalid person!");
    }

  }).catch(err => {
    console.error(err);
  });
};