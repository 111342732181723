import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { HandleRemoveLog } from "../../functions/HandleRemoveLog";
import { Retriever } from "../../functions/Retriever ";

const Logs = (props) => {
  const {
    client,
    accountData
  } = props;

  moment.locale('en');

  const today = new Date();
  const yesterday = new Date(today);

  const [fromDate, setFromDate] = useState(yesterday.setDate(today.getDate() - 30));
  const [toDate, setToDate] = useState(today);
  const [searchPost, setSearchPost] = useState("");
  const [logData, setLogData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);

  // useEffect(() => {
  //   if (!accountData?.flast_id) { return; }
  //   client.data
  //     .getter()
  //     .withClassName('Logs')
  //     .do()
  //     .then(res => {
  //       // console.log("Logs", res);
  //       setLogData(res.objects);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // }, [refreshData]);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }
    const resPromise = Retriever("Logs", 100);
    resPromise.then(res => {
      setLogData(res);// This will log the resolved value of the Promise
    }).catch(err => {
      console.error(err); // Handle errors here
    });
  }, []);

  // filtered for search and sorted by time
  const postSearch = logData
    .sort((a, b) => b.creationTimeUnix - a.creationTimeUnix)
    .filter((data) => {
      const matchesSearch =
        data.properties.reason.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.employee_name.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.employee_email.toLowerCase().includes(searchPost.toLowerCase()) ||
        moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow().toLowerCase().includes(searchPost.toLowerCase());

      const matchesDateRange =
        (!fromDate || data.creationTimeUnix >= fromDate) &&
        (!toDate || data.creationTimeUnix <= toDate);

      return matchesSearch && matchesDateRange;
    });

  return (
    <>
      <form className="mb-5">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex items-center lg:order-2 mb-3 lg:mb-0">
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              From-<ReactDatePicker className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" selected={fromDate} onChange={(date) => setFromDate(date)} showTimeSelect dateFormat="Pp" />
            </div>
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              To-<ReactDatePicker className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" selected={toDate} onChange={(date) => setToDate(date)} showTimeSelect dateFormat="Pp" />
            </div>
          </div>
          <div className="relative lg:order-1 w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
            </div>
            <input onChange={(e) => setSearchPost(e.target.value)} type="text" placeholder="Search for records" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          </div>
        </div>
      </form>



      <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[45rem] scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md">

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3 text-center">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Reason
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Loged
              </th>
              <th scope="col" className="px-6 py-3 float-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody>

            {postSearch.map((data, idx) => {
              return (
                <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" >
                  <th className="text-center">
                    {idx + 1}
                  </th>
                  < th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" >
                    {data.properties.employee_name}
                  </th>
                  <td className="px-6 py-4">
                    <p className="cursor-help text-gray-600 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-100">
                      {data.properties.employee_email}
                    </p>
                  </td>
                  <td className="px-6 py-4 text-center">
                    {data.properties.reason}
                  </td>
                  <td className="px-6 py-4 text-center">
                    <p title={moment(data.creationTimeUnix).format("MMMM Do YYYY, h:mm:ss a")} className="cursor-help" >
                      {moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow()}
                    </p>
                  </td>
                  <td className="px-6 py-4 float-right">
                    <FontAwesomeIcon
                      onClick={
                        () => HandleRemoveLog(
                          data.id,
                          client,
                          accountData,
                          setRefreshData
                        )
                      }
                      icon={faTrash}
                      title="Delete log"
                      className="cursor-pointer ml-1 text-red-500 hover:text-red-600"
                    />
                  </td>
                </tr>
              );
            })}


          </tbody>
        </table>
      </div>
    </>
  );
};

export default Logs;