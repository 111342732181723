import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import moment from "moment";
import { Retriever } from "../../functions/Retriever ";

const Records = (props) => {
  const {
    client,
    accountData
  } = props;

  moment.locale('en');

  const today = new Date();
  const yesterday = new Date(today);

  const [recordData, setRecordData] = useState([]);
  const [fromDate, setFromDate] = useState(yesterday.setDate(today.getDate() - 30));
  const [toDate, setToDate] = useState(today);
  const [searchPost, setSearchPost] = useState("");

  // console.log(moment(toDate).unix());

  // useEffect(() => {
  //   if (!accountData?.flast_id) { return; }
  //   client.data
  //     .getter()
  //     .withClassName('Records')
  //     // .withAfter("")
  //     // .withLimit(25)
  //     .do()
  //     .then(res => {
  //       // console.log("Records", res.objects);
  //       setRecordData(res.objects);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // }, []);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }
    const resPromise = Retriever("Records", 100);
    resPromise.then(res => {
      setRecordData(res);// This will log the resolved value of the Promise
    }).catch(err => {
      console.error(err); // Handle errors here
    });
  }, []);




  function extractUUIDFromUrl(url) {
    const match = url.match(/\/([\w-]+)$/);
    return match ? match[1] : null;
  }

  // filtered for search and sorted by time
  const recordSearch = recordData
    .sort((a, b) => b.creationTimeUnix - a.creationTimeUnix)
    .filter((data) => {
      const matchesSearch =
        data.properties.data_kind.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.action_type.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.employee_name.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.employee_email.toLowerCase().includes(searchPost.toLowerCase()) ||
        extractUUIDFromUrl(data.properties.dat_id[0].href).toLowerCase().includes(searchPost.toLowerCase()) ||
        moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow().toLowerCase().includes(searchPost.toLowerCase());

      const matchesDateRange =
        (!fromDate || data.creationTimeUnix >= fromDate) &&
        (!toDate || data.creationTimeUnix <= toDate);

      return matchesSearch && matchesDateRange;
    });

  return (
    <div className="">
      {/* bg-gray-900 h-auto */}

      <form className="mb-5">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex items-center lg:order-2 mb-3 lg:mb-0">
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              From-<ReactDatePicker className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" selected={fromDate} onChange={(date) => setFromDate(date)} showTimeSelect dateFormat="Pp" />
            </div>
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              To-<ReactDatePicker className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" selected={toDate} onChange={(date) => setToDate(date)} showTimeSelect dateFormat="Pp" />
            </div>
          </div>
          <div className="relative lg:order-1 w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
            </div>
            <input onChange={(e) => setSearchPost(e.target.value)} type="text" placeholder="Search for records" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          </div>
        </div>
      </form>



      <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[45rem] scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md">

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3 text-center">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Action Type
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Data Kind
              </th>
              <th scope="col" className="px-6 py-3">
                Data ID
              </th>
              <th scope="col" className="px-6 py-3 float-right">
                Date Time
              </th>
            </tr>
          </thead>
          <tbody>

            {recordSearch?.map((data, idx) => {
              return (
                <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" >
                  <th className="text-center">
                    {recordSearch.length - idx}
                  </th>
                  < th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" >
                    {data.properties.employee_name}
                  </th>
                  <td className="px-6 py-4">
                    <p title={data.properties.employee_email} className="cursor-help text-gray-600 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-100 truncate w-36">
                      {data.properties.employee_email}
                    </p>
                  </td>
                  <td className="px-6 py-4 text-center">
                    {data.properties.action_type}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {data.properties.data_kind}
                  </td>
                  <td className="px-6 py-4">
                    <p
                      title={extractUUIDFromUrl(data.properties.dat_id[0].href)}
                      className={
                        `${data.properties.action_type === "remove" ? "text-red-400" :
                          data.properties.action_type === "update" ? "text-yellow-400" :
                            "text-blue-400"
                        } cursor-help truncate w-36 font-semibold`
                      }
                    >
                      {extractUUIDFromUrl(data.properties.dat_id[0].href)}
                    </p>
                  </td>
                  <td className="px-6 py-4 float-right">
                    <p title={moment(data.creationTimeUnix).format("MMMM Do YYYY, h:mm:ss a")} className="cursor-help" >
                      {moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow()}
                    </p>
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
        {/* <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span className="font-semibold text-gray-900 dark:text-white">1-10</span> of <span className="font-semibold text-gray-900 dark:text-white">1000</span></span>
          <ul className="inline-flex items-center -space-x-px">
            <li>
              <b className="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span className="sr-only">Previous</span>
                <FontAwesomeIcon icon={faAngleLeft} />
              </b>
            </li>
            <li>
              <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                1
              </b>
            </li>
            <li>
              <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                2
              </b>
            </li>
            <li>
              <b aria-current="page" className="z-10 px-3 py-2 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">
                3
              </b>
            </li>
            <li>
              <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                ...
              </b>
            </li>
            <li>
              <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                100
              </b>
            </li>
            <li>
              <b className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span className="sr-only">Next</span>
                <FontAwesomeIcon icon={faAngleRight} />
              </b>
            </li>
          </ul>
        </nav> */}
      </div>
    </div>
  );
};

export default Records;