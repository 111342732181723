import weaviate from "weaviate-client";
import { toastEffect } from "../ToastEffect";
import { Record } from "./Record";
import { UpdatePerson } from "./UpdatePerson";
import { Retriever } from "./Retriever ";

export const HandleAddPOST = async (
  e,
  client,
  setPostData,
  setSavingPost,
  accountData,
  setPostValue,
  setPostName,
  setPostProfile,
  setPersonData
) => {

  e.preventDefault();
  e.stopPropagation();

  if (!accountData?.flast_id) { return; }

  // const fb_name = e.target.elements.facebook_post_name.value;
  // const fb_profile = e.target.elements.facebook_post_profile.value;
  const fb_post = e.target.elements.facebook_post.value;
  const fb_person = e.target.elements.facebook_post_person.value;

  const name = fb_person.split(' - ')[0];
  const fbLink = fb_person.split(' - ')[1];

  if (fb_person === "" || fb_post === "") {
    return;
  }


  setSavingPost(true);

  // await client.data
  //   .getter()
  //   .withClassName('Persons')
  //   .do()
  //   .then(async res => {

  const resPromise = Retriever("Persons", 100);
  resPromise.then(async res => {

    const result = res.find(obj => obj.properties.fb_profile === fbLink);
    const profileIDExist = { exists: !!result, data: result };
    // console.log(profileIDExist);
    if (profileIDExist.exists) {
      await client.data
        .creator()
        .withClassName('POST')
        .withProperties({
          'fb_name': name,
          'fb_profile': fbLink,
          'fb_post': fb_post,
          'writesFor': [{
            beacon: `weaviate://localhost/Persons/${profileIDExist.data.id}`,
            href: `/v1/objects/Persons/${profileIDExist.data.id}`
          }]
        })
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
        .do()
        .then(async res => {
          // console.log(res);
          await Record(client, accountData, res.class, res.id, "insert");
          await UpdatePerson(client, res.class, res.id, profileIDExist.data.id);

          const newPost = {
            class: "POST",
            creationTimeUnix: Date.now(),
            id: res.id,
            lastUpdateTimeUnix: Date.now(),
            properties: {
              fb_name: name,
              fb_post: fb_post,
              fb_profile: fbLink
            },
            vectorWeights: null
          };

          setPostValue("");
          setPersonData("");
          setPostData(prevData => [...prevData, newPost]);
          setSavingPost(false);
          toastEffect("success", "Post added successfully.");
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      setSavingPost(false);
      toastEffect("error", "Invalid person!");
    }

  }).catch(err => {
    console.error(err); // Handle errors here
  });
  // })
  // .catch(err => {
  //   console.error(err);
  // });
};