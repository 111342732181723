import weaviate from "weaviate-client";

export const UpdatePerson = async (client, data_kind, data_id, person_id) => {

  await client.data
    .getterById()
    .withId(person_id)
    .withClassName("Persons")
    .do()
    .then(async res => {
      // alter the schema
      const schema = res.properties;
      schema.linkedTo = [{
        beacon: `weaviate://localhost/${data_kind}/${data_id}`,
        href: `/v1/objects/${data_kind}/${data_id}`
      }];
      await client.data
        .merger()
        .withId(person_id)
        .withClassName("Persons")
        .withProperties(schema)
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
        .do()
        .then(async res => {
          // console.log(res);
        });
    }).catch(err => {
      console.error(err);
    });
};