import weaviate from "weaviate-client";

export const Record = async (client, accountData, data_kind, data_id, action) => {

  await client.data
    .creator()
    .withClassName('Records')
    .withProperties({
      'employee_name': accountData?.name,
      'employee_email': accountData?.email,
      'data_kind': data_kind,
      'action_type': action,
      'dat_id': [{
        'beacon': `weaviate://localhost/${data_kind}/${data_id}`
      }]
    })
    .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
    .do()
    .then(res => {
      // toastEffect("success", "Comment added successfully.");
    })
    .catch(err => {
      console.error(err);
    });
};