import Swal from "sweetalert2";
import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";

export const HandleRemovePOST = (
  postID,
  client,
  setPostData,
  postData,
  contentCount,
  accountData
) => {

  if (!accountData?.flast_id) { return; }

  let color = "";

  if (localStorage.theme === "dark") {
    color = "#111827";
  } else {
    color = "";
  }

  Swal.fire({
    background: color,
    html:
      `
        <label class= "text-gray-800 dark:text-gray-100 font-semibold text-2xl">Remove POST</label>
        <form class="border p-5 rounded-lg mt-3 border-red-500">
          <p class="text-gray-800 dark:text-gray-100">This action will remove this post permanenty.</p>
        </form>
      `,
    showCancelButton: true,
    confirmButtonText: 'Remove',
    showLoaderOnConfirm: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'bg-red-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-red-500 w-36',
      cancelButton: 'bg-blue-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-blue-500 w-20'
    },
    preConfirm: async () => {
      if (contentCount > 0) {
        return toastEffect("error", `Remove Comments and responses first!`);
      } else {
        await Record(client, accountData, "POST", postID, "remove");
        await client.data
          .deleter()
          .withClassName('POST')
          .withId(postID)
          .withConsistencyLevel(weaviate.replication.ConsistencyLevel.QUORUM)
          .do()
          .then(res => {
            const filteredData = postData.filter(post => post.id !== postID);
            setPostData(filteredData);
            toastEffect("success", "Succesfully removed!");
          })
          .catch(err => {
            console.error(err);
          });
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  });

};