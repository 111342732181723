import { useState, useEffect } from "react";
import { faTrash, faPencil, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { HandleUpdatePOST } from "../functions/HandleUpdatePOST";
import { HandleRemovePOST } from "../functions/HandleRemovePOST";
import { Retriever } from "../functions/Retriever ";


const Posts = (props) => {

  const {
    client,
    accountData,
  } = props;

  moment.locale('en');

  const today = new Date();
  const yesterday = new Date(today);
  const tomorrow = new Date(today);

  const [fromDate, setFromDate] = useState(yesterday.setDate(today.getDate() - 30));
  const [toDate, setToDate] = useState(tomorrow.setDate(today.getDate() + 1));
  const [searchPost, setSearchPost] = useState("");
  const [postData, setPostData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [countComments, setCountComments] = useState([]);
  const [countResponses, setCountResponses] = useState([]);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }

    const resPromise = Retriever("POST", 100);
    resPromise.then(res => {
      setPostData(res);// This will log the resolved value of the Promise
      // console.log(res);
    }).catch(err => {
      console.error(err); // Handle errors here
    });

    const resPromiseComment = Retriever("Comment", 100);
    resPromiseComment.then(res => {
      setCountComments(res);// This will log the resolved value of the Promise
      // console.log(res);
    }).catch(err => {
      console.error(err); // Handle errors here
    });

    const resPromiseResponse = Retriever("Response", 100);
    resPromiseResponse.then(res => {
      setCountResponses(res);// This will log the resolved value of the Promise
      // console.log(res);
    }).catch(err => {
      console.error(err); // Handle errors here
    });

  }, [props, refreshData]);

  // filtered for search and sorted by time
  const postSearch = postData
    .sort((a, b) => b.creationTimeUnix - a.creationTimeUnix)
    .filter((data) => {
      const matchesSearch =
        data.properties.fb_name.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.fb_post.toLowerCase().includes(searchPost.toLowerCase()) ||
        data.properties.fb_profile.toLowerCase().includes(searchPost.toLowerCase()) ||
        moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow().toLowerCase().includes(searchPost.toLowerCase());

      const matchesDateRange =
        (!fromDate || data.creationTimeUnix >= fromDate) &&
        (!toDate || data.creationTimeUnix <= toDate);

      return matchesSearch && matchesDateRange;
    });

  function extractUUIDFromUrl(url) {
    // return url.split("https://www.facebook.com/").pop();
    return url.split('/').pop().split('?')[0];
  }

  return (
    <>
      <form className="mb-5">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex items-center lg:order-2 mb-3 lg:mb-0">
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              From-<ReactDatePicker
                onChange={(date) => setFromDate(date)}
                selected={fromDate}
                showTimeSelect
                dateFormat="Pp"
                className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" />
            </div>
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              To-<ReactDatePicker
                onChange={(date) => setToDate(date)}
                selected={toDate}
                showTimeSelect
                dateFormat="Pp"
                className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" />
            </div>
          </div>
          <div className="relative lg:order-1 w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
            </div>
            <input onChange={(e) => setSearchPost(e.target.value)} type="text" placeholder="Search for posts" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          </div>
        </div>
      </form>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[33.7rem] scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {/* <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                </div>
              </th> */}
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                FB Name
              </th>
              <th scope="col" className="px-6 py-3">
                FB Post
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                FB Profile
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Comments
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Responses
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Created
              </th>
              <th scope="col" className="px-6 py-3 float-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {postSearch?.map((data, idx) => {

              let totalResponseCount = 0;

              const filteredCommentCount = countComments?.filter(obj => obj.properties.writesFor.some(w => w.href.includes(`/v1/objects/POST/${data.id}`)));

              filteredCommentCount?.forEach((comment) => {
                const responseCount = countResponses?.filter(obj => obj.properties.writesFor.some(w => w.href.includes(`/v1/objects/Comment/${comment.id}`)))?.length;
                if (!isNaN(responseCount)) {
                  totalResponseCount += responseCount;
                }
              });

              return (
                <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" >
                  {/* <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                    </div>
                  </td> */}
                  <th className="text-center">
                    {postSearch.length - idx}
                  </th>
                  < th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" >
                    {data.properties.fb_name}
                  </th>
                  <td className="px-6 py-4">
                    <p title="Click to view post" className="cursor-pointer text-gray-600 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-100 truncate w-56">
                      <Link to={`/responses?id=${data.id}`}>{data.properties.fb_post}</Link>
                    </p>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <a title="Visit facebook profile" className="text-blue-400 hover:underline" href={data.properties.fb_profile} target="_blank">
                      <p className="font-semibold">{extractUUIDFromUrl(data.properties.fb_profile)}</p>
                    </a>
                  </td>
                  <td className="px-6 py-4 text-center">
                    {filteredCommentCount?.length}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {totalResponseCount}
                  </td>
                  <td className="px-6 py-4 text-center">
                    <p title={moment(data.creationTimeUnix).format("MMMM Do YYYY, h:mm:ss a")} className="cursor-help" >
                      {moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow()}
                    </p>
                  </td>
                  <td className="px-6 py-4 float-right">
                    <FontAwesomeIcon
                      onClick={() => HandleUpdatePOST(
                        data.id,
                        data.properties.fb_profile,
                        data.properties.fb_name,
                        data.properties.fb_post,
                        client,
                        setRefreshData,
                        accountData
                      )}
                      title="Edit data"
                      className="cursor-pointer mr-1 text-blue-500 hover:text-blue-600"
                      icon={faPencil}
                    />
                    <FontAwesomeIcon
                      onClick={() => HandleRemovePOST(
                        data.id,
                        client,
                        setPostData,
                        postData,
                        filteredCommentCount?.length + totalResponseCount,
                        accountData
                      )}
                      title="Delete data"
                      className="cursor-pointer ml-1 text-red-500 hover:text-red-600"
                      icon={faTrash}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span className="font-semibold text-gray-900 dark:text-white">1-10</span> of <span className="font-semibold text-gray-900 dark:text-white">1000</span></span>
              <ul className="inline-flex items-center -space-x-px">
                <li>
                  <b className="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <span className="sr-only">Previous</span>
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </b>
                </li>
                <li>
                  <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    1
                  </b>
                </li>
                <li>
                  <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    2
                  </b>
                </li>
                <li>
                  <b aria-current="page" className="z-10 px-3 py-2 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">
                    3
                  </b>
                </li>
                <li>
                  <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    ...
                  </b>
                </li>
                <li>
                  <b className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    100
                  </b>
                </li>
                <li>
                  <b className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <span className="sr-only">Next</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </b>
                </li>
              </ul>
            </nav> */}
      </div>
    </>
  );
};

export default Posts;