import { faPencil, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { HandleRemovePerson } from "../functions/HandleRemovePerson";
import { HandleUpdatePerson } from "../functions/HandleUpdatePerson";
import { Retriever } from "../functions/Retriever ";

const Persons = (props) => {

  const {
    client,
    accountData,
  } = props;

  moment.locale('en');

  const today = new Date();
  const yesterday = new Date(today);
  const tomorrow = new Date(today);

  const [fromDate, setFromDate] = useState(yesterday.setDate(today.getDate() - 30));
  const [toDate, setToDate] = useState(tomorrow.setDate(today.getDate() + 1));
  const [searchPerson, setSearchPerson] = useState("");
  const [persons, setPersons] = useState([]);
  const [refreshData, setRefreshData] = useState(0);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }
    const resPromise = Retriever("Persons", 100);
    resPromise.then(res => {
      setPersons(res);// This will log the resolved value of the Promise
    }).catch(err => {
      console.error(err); // Handle errors here
    });
  }, [refreshData]);

  // filtered for search and sorted by time
  const postSearch = persons
    .sort((a, b) => b.creationTimeUnix - a.creationTimeUnix)
    .filter((data) => {
      const matchesSearch =
        data.properties.fb_name.toLowerCase().includes(searchPerson.toLowerCase()) ||
        data.properties.fb_profile.toLowerCase().includes(searchPerson.toLowerCase()) ||
        moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow().toLowerCase().includes(searchPerson.toLowerCase());

      const matchesDateRange =
        (!fromDate || data.creationTimeUnix >= fromDate) &&
        (!toDate || data.creationTimeUnix <= toDate);

      return matchesSearch && matchesDateRange;
    });

  function extractUUIDFromUrl(url) {
    // return url.split("https://www.facebook.com/").pop();
    return url.split('/').pop().split('?')[0];
  }

  return (
    <>
      <form className="mb-5">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex items-center lg:order-2 mb-3 lg:mb-0">
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              From-<ReactDatePicker
                onChange={(date) => setFromDate(date)}
                selected={fromDate}
                showTimeSelect
                dateFormat="Pp"
                className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" />
            </div>
            <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 dark:bg-gray-800 dark:text-white dark:border-gray-600">
              To-<ReactDatePicker
                onChange={(date) => setToDate(date)}
                selected={toDate}
                showTimeSelect
                dateFormat="Pp"
                className="text-gray-700 dark:text-gray-300 w-full mr-1 rounded-md cursor-pointer dark:bg-slate-800" />
            </div>
          </div>
          <div className="relative lg:order-1 w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
            </div>
            <input onChange={(e) => setSearchPerson(e.target.value)} type="text" placeholder="Search for person" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          </div>
        </div>
      </form>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[33.7rem] scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                FB Name
              </th>
              <th scope="col" className="px-6 py-3">
                FB Profile
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Posts
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Comments
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Responses
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Added
              </th>
              <th scope="col" className="px-6 py-3 float-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody>

            {postSearch.map((data, idx) => {

              const postCount = data?.properties?.linkedTo?.filter(link => link.href.includes('/v1/objects/POST')).length;
              const commentCount = data?.properties?.linkedTo?.filter(link => link.href.includes('/v1/objects/Comment')).length;
              const responseCount = data?.properties?.linkedTo?.filter(link => link.href.includes('/v1/objects/Response')).length;

              return (
                <tr key={idx} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" >
                  <th className="text-center">
                    {postSearch.length - idx}
                  </th>
                  < th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" >
                    {data.properties.fb_name}
                  </th>
                  <td className="px-6 py-4 text-center">
                    <a title="Visit facebook profile" className="text-blue-400 hover:underline" href={data.properties.fb_profile} target="_blank">
                      <p className="font-semibold">{extractUUIDFromUrl(data.properties.fb_profile)}</p>
                    </a>
                  </td>
                  <td className="px-6 py-4">
                    {postCount || 0}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {commentCount || 0}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {responseCount || 0}
                  </td>
                  <td className="px-6 py-4 text-center">
                    <p title={moment(data.creationTimeUnix).format("MMMM Do YYYY, h:mm:ss a")} className="cursor-help" >
                      {moment.utc(data.creationTimeUnix).local().startOf('seconds').fromNow()}
                    </p>
                  </td>
                  <td className="px-6 py-4 float-right">
                    <FontAwesomeIcon
                      onClick={() => HandleUpdatePerson(
                        data.id,
                        client,
                        setRefreshData,
                        accountData,
                        data.properties.fb_name,
                        data.properties.fb_profile
                      )}
                      title="Edit data"
                      className="cursor-pointer mr-1 text-blue-500 hover:text-blue-600"
                      icon={faPencil}
                    />
                    <FontAwesomeIcon
                      onClick={() => HandleRemovePerson(
                        data.id,
                        client,
                        persons,
                        setPersons,
                        accountData
                      )}
                      title="Delete data"
                      className="cursor-pointer ml-1 text-red-500 hover:text-red-600"
                      icon={faTrash}
                    />
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
    </>
  );
};

export default Persons;