import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from "react";
import { faArrowsRotate, faListOl, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HandleAddPOST } from "../functions/HandleAddPost";
import { HandleNewPerson } from "../functions/HandleNewPerson";
import weaviate from "weaviate-client";
import LeftSide from "./LeftSide";
import Posts from "./Posts";
import Persons from "./Persons";
import { Retriever } from "../functions/Retriever ";

const DataEntry = ({ accountData }) => {

  // Account token
  const token = JSON.parse(localStorage.getItem("account"));

  const [postData, setPostData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [savingpost, setSavingPost] = useState(false);
  const [savingPerson, setSavingPerson] = useState(false);
  const textAreaPost = useRef(null);
  const [postValue, setPostValue] = useState("");
  const [countComments, setCountComments] = useState([]);
  const [countResponses, setCountResponses] = useState([]);
  const [persons, setPersons] = useState([]);
  const [postName, setPostName] = useState("");
  const [postProfile, setPostProfile] = useState("");
  const [personName, setPersonName] = useState("");
  const [personProfile, setPersonProfile] = useState("");
  const [personData, setPersonData] = useState("");
  const [selectedTab, setSelectedTab] = useState("posts");

  // check is token existed
  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  const handleSelectedTab = (tab) => {
    localStorage.setItem("selectedTab", tab);
    setSelectedTab(tab);
  };


  // Weaviate connection config
  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  // Text area row effect
  const resizeTextArea = (refData) => {
    refData.current.style.height = "auto";
    if (refData.current.scrollHeight >= 200) {
      refData.current.style.height = 200 + "px";
    } else {
      refData.current.style.height = refData.current.scrollHeight + "px";
    }
  };

  useEffect(() => resizeTextArea(textAreaPost), [postValue]);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }
    const resPromise = Retriever("Persons", 100);
    resPromise.then(res => {
      setPersons(res);// This will log the resolved value of the Promise
      // console.log(res);
    }).catch(err => {
      console.error(err); // Handle errors here
    });
  }, [refreshData, accountData]);

  // // Get all schema
  // useEffect(() => {
  //   client.schema
  //     .getter()
  //     .do()
  //     .then(res => {
  //       console.log("Schema", res);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // }, []);

  // Get all Objects and record data
  // useEffect(() => {
  //   if (!accountData?.flast_id) { return; }
  //   const getAllData = async () => {

  //     await client.data
  //       .getter()
  //       .withClassName('Persons')
  //       // .withLimit(2)
  //       .do()
  //       .then(res => {
  //         setPersons(res.objects);
  //         // console.log("Persons", res.objects);
  //       })
  //       .catch(err => {
  //         console.error(err);
  //       });

  //     await client.data
  //       .getter()
  //       .withClassName('POST')
  //       .do()
  //       .then(res => {
  //         setPostData(res.objects);
  //         // console.log(JSON.stringify(res.objects));
  //         // console.log("Objects&Data", res.objects);
  //       })
  //       .catch(err => {
  //         console.error(err);
  //       });

  //     await client.data
  //       .getter()
  //       .withClassName('Comment')
  //       .do()
  //       .then(res => {
  //         setCountComments(res);
  //         // console.log("Comments", res);
  //       })
  //       .catch(err => {
  //         console.error(err);
  //       });

  //     await client.data
  //       .getter()
  //       .withClassName('Response')
  //       .do()
  //       .then(res => {
  //         setCountResponses(res);
  //         // console.log("Reponses", res);
  //       })
  //       .catch(err => {
  //         console.error(err);
  //       });
  //   };
  //   getAllData();
  // }, [refreshData, accountData]);

  return (
    <>
      <LeftSide accountData={accountData} />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <div className="grid lg:grid-cols-2 gap-4 mb-4">
            <form
              onSubmit={
                (e) => HandleAddPOST(
                  e,
                  client,
                  setPostData,
                  setSavingPost,
                  accountData,
                  setPostValue,
                  setPostName,
                  setPostProfile,
                  setPersonData
                )
              }
              className="border-gray-400 dark:border-gray-600 p-3 border rounded-md"
            >
              <label className="text-gray-600 dark:text-gray-200 font-bold">Add new post</label>

              {/* <label for="underline_select" className="sr-only">Underline select</label>
              <select id="underline_select" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                <option selected>Choose a country</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
              </select> */}

              <div className="grid md:grid-cols-1 md:gap-6 mt-3">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    value={personData}
                    onInput={(e) => setPersonData(e.target.value)}
                    placeholder="Select Person"
                    list="countries"
                    id="facebook_post_person"
                    name="countries"
                    required
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  />
                  <datalist id="countries">
                    {persons.map((data, idx) => {
                      return (
                        <option key={idx} value={`${data.properties.fb_name} - ${data.properties.fb_profile}`} />
                      );
                    })}
                  </datalist>
                </div>

                {/* <div className="grid md:grid-cols-2 md:gap-6 mt-3">
                  <div className="relative z-0 w-full mb-6 group">
                    <input placeholder=" " readOnly value={postName} onChange={(e) => setPostName(e.target.value)} type="text" name="facebook_post_name" id="facebook_post_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                    <label htmlFor="facebook_post_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      Facebook Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input placeholder=" " readOnly value={postProfile} onChange={(e) => setPostProfile(e.target.value)} type="text" name="facebook_post_profile" id="facebook_post_profile" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                    <label htmlFor="facebook_post_profile" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                      Facebook Profile
                    </label>
                  </div>
                </div> */}
                <div className="relative z-0 w-full mb-6 group">
                  <textarea
                    ref={textAreaPost}
                    onChange={(e) => setPostValue(e.target.value)}
                    value={postValue}
                    rows="1"
                    type="text"
                    name="facebook_post"
                    id="facebook_post"
                    className="scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required />
                  <label htmlFor="facebook_post" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Post
                  </label>
                </div>
              </div>
              <button disabled={savingpost ? true : false} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <FontAwesomeIcon spin={savingpost ? true : false} icon={faArrowsRotate} size="1x" /> {savingpost ? "Saving..." : "Save Post"}
              </button>
            </form>
            <form
              onSubmit={
                (e) => HandleNewPerson(
                  e,
                  client,
                  accountData,
                  setSavingPerson,
                  setPersonName,
                  setPersonProfile,
                  setPersons
                )
              }
              className="border-gray-400 dark:border-gray-600 p-3 border rounded-md"
            >
              <label className="text-gray-600 dark:text-gray-200 font-bold">Add new person</label>
              <div className="grid md:grid-cols-1 md:gap-6 mt-3">
                <div className="relative z-0 w-full mb-6 group">
                  <input value={personName} onChange={(e) => setPersonName(e.target.value)} type="text" name="facebook_person_name" id="facebook_person_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="facebook_person_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input value={personProfile} onChange={(e) => setPersonProfile(e.target.value)} type="text" name="facebook_person_profile" id="facebook_person_profile" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="facebook_person_profile" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Facebook Profile
                  </label>
                </div>
              </div>
              {/* <div className="relative z-0 w-full mb-6 group">
                <textarea
                  rows="1"
                  type="text"
                  name="facebook_comment"
                  id="facebook_comment"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required />
                <label htmlFor="facebook_comment" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                  Facebook Comment
                </label>
              </div> */}
              <button disabled={savingPerson ? true : false} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <FontAwesomeIcon spin={savingPerson ? true : false} icon={faArrowsRotate} size="1x" /> {savingPerson ? "Saving..." : "Save Person"}
              </button>
            </form>
          </div>

          <div className="mb-3 border-b border-gray-200 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">

              <li className="mr-2">
                <h1 onClick={() => handleSelectedTab("posts")} className={`${selectedTab === "posts" ? "border-blue-600 text-blue-600" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"} cursor-pointer inline-flex p-4 border-b-2 rounded-t-lg`}>
                  <FontAwesomeIcon icon={faListOl} className="w-4 h-4 mr-2" />
                  Posts
                </h1>
              </li>
              <li className="mr-2">
                <h1 onClick={() => handleSelectedTab("persons")} className={`${selectedTab === "persons" ? "border-blue-600 text-blue-600" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"} cursor-pointer inline-flex p-4 border-b-2 rounded-t-lg`}>
                  <FontAwesomeIcon icon={faUsers} className="w-4 h-4 mr-2" />
                  Persons
                </h1>
              </li>
              {/* <li className="mr-2">
                <h1 onClick={() => handleSelectedTab("logs")} className={`${selectedTab === "logs" ? "border-blue-600 text-blue-600" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"} cursor-pointer inline-flex p-4 border-b-2 rounded-t-lg`}>
                  <FontAwesomeIcon icon={faFilePen} className="w-4 h-4 mr-2" />
                  Logs
                </h1>
              </li>
              <li className="ml-auto">
                <button title="Create all required Schema" disabled={creatingSchema ? true : false} onClick={() => HandleSchemaCreate(setCreatingSchema)} type="submit" className="mr-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <FontAwesomeIcon spin={creatingSchema ? true : false} icon={faArrowsRotate} size="1x" /> {creatingSchema ? "Creating..." : "Create"}
                </button>
                <button title="Delete all Schema" disabled={deletingSchema ? true : false} onClick={() => HandleSchemaDelete(setDeletingSchema)} type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <FontAwesomeIcon spin={deletingSchema ? true : false} icon={faArrowsRotate} size="1x" /> {deletingSchema ? "Deleting..." : "Delete"}
                </button>
              </li> */}
            </ul>
          </div>

          {selectedTab === "persons" ? (
            <Persons
              client={client}
              accountData={accountData}
            />
          ) : (
            <Posts
              client={client}
              accountData={accountData}
            />
          )}

          {/* <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div >
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
          </div>
          <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
            </div>
          </div> */}
        </div>
      </div >

    </>
  );
};

export default DataEntry;