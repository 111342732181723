import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";
import { classObjectsArray } from "./ClassObjects";

export const HandleSchemaCreate = async (setCreatingSchema) => {

  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  const classList = ['POST', 'Comment', 'Response', 'Accounts', 'Records', 'Logs', 'Persons'];

  function getObject(classData) {
    switch (classData) {
      case "POST": return classObjectsArray[0];
      case "Response": return classObjectsArray[1];
      case "Accounts": return classObjectsArray[2];
      case "Comment": return classObjectsArray[3];
      case "Records": return classObjectsArray[4];
      case "Logs": return classObjectsArray[5];
      case "Persons": return classObjectsArray[6];
      default:
        // Handle the case where the class name is not found
        console.log(`Class ${classData} not found`);
    };
  }
  setCreatingSchema(true);

  for (const className of classList) {
    await client.data
      .getter()
      .withClassName(className)
      .do()
      .then(async res => {
        console.log(`Class ${className} exists`);
      })
      .catch(async err => {
        await client.schema
          .classCreator()
          .withClass(getObject(className))
          .do()
          .then(async res => {
            toastEffect("success", `Schema ${res.class} created successfully.`);
          })
          .catch(err => {
            console.error(err);
          });

        // This condition will update  the POST Class
        if (className === "Persons") {
          const _className = "POST";
          await client.schema
            .propertyCreator()
            .withClassName(_className)
            .withProperty(classObjectsArray[7])
            .do()
            .then(res => {
              setCreatingSchema(false);
              toastEffect("success", `Schema ${_className} updated successfully.`);
            })
            .catch(err => {
              console.error(err);
            });
        } else if (className === "Comment") {
          const _className = "POST";
          await client.schema
            .propertyCreator()
            .withClassName(_className)
            .withProperty(classObjectsArray[8])
            .do()
            .then(res => {
              setCreatingSchema(false);
              toastEffect("success", `Schema ${_className} updated successfully.`);
            })
            .catch(err => {
              console.error(err);
            });
        } else if (className === "Response") {
          const _className = "Comment";
          await client.schema
            .propertyCreator()
            .withClassName(_className)
            .withProperty(classObjectsArray[9])
            .do()
            .then(res => {
              setCreatingSchema(false);
              toastEffect("success", `Schema ${_className} updated successfully.`);
            })
            .catch(err => {
              console.error(err);
            });
        }
        // console.error(err);
      });
  }
};