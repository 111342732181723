import weaviate from "weaviate-client";
import { toastEffect } from "../ToastEffect";
import { Record } from "./Record";
import { UpdatePerson } from "./UpdatePerson";
import { UpdateComment } from "./UpdateComment";

export const HandleResponse = async (
  e,
  setSavingResponse,
  client,
  setResponses,
  setResponseValue,
  setResponseName,
  setResponseProfile,
  accountData,
  setPersonData
) => {
  e.preventDefault();
  e.stopPropagation();

  if (!accountData?.flast_id) { return; }

  // const fb_name = e.target.elements.facebook_response_name.value;
  // const fb_profile = e.target.elements.facebook_response_profile.value;
  const fb_response = e.target.elements.facebook_response.value;
  const fb_comment_id = e.target.elements.comment_id.value;
  const fb_person = e.target.elements.facebook_post_person.value;

  const name = fb_person.split(' - ')[0];
  const fbLink = fb_person.split(' - ')[1];

  if (fb_response === "" || fb_person === "" || fb_comment_id === "") {
    return;
  }

  setSavingResponse(true);

  await client.data
    .getter()
    .withClassName('Persons')
    .do()
    .then(async res => {
      const result = res.objects.find(obj => obj.properties.fb_profile === fbLink);
      const profileIDExist = { exists: !!result, data: result };
      // console.log(profileIDExist);
      if (profileIDExist.exists) {
        await client.data
          .creator()
          .withClassName('Response')
          .withProperties({
            'fb_name': name,
            'fb_profile': fbLink,
            'fb_response': fb_response,
            'writesFor': [{
              beacon: `weaviate://localhost/Comment/${fb_comment_id}`,
              href: `/v1/objects/Comment/${fb_comment_id}`
            }]
          })
          .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
          .do()
          .then(async res => {

            await Record(client, accountData, res.class, res.id, "insert");
            await UpdateComment(client, res.class, res.id, fb_comment_id);
            await UpdatePerson(client, res.class, res.id, profileIDExist.data.id);

            const newResponse = {
              class: "Response",
              creationTimeUnix: Date.now(),
              id: res.id,
              lastUpdateTimeUnix: Date.now(),
              properties: {
                fb_name: name,
                fb_response: fb_response,
                fb_profile: fbLink,
                writesFor: [{
                  beacon: `weaviate://localhost/Comment/${fb_comment_id}`,
                  href: `/v1/objects/Comment/${fb_comment_id}`
                }]
              },
              vectorWeights: null
            };

            setResponses(prevData => [...prevData, newResponse]);

            setResponseValue("");
            setPersonData("");
            setSavingResponse(false);
            toastEffect("success", "Response added successfully.");
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        setSavingResponse(false);
        toastEffect("error", "Invalid person!");
      }
    })
    .catch(err => {
      console.error(err);
    });
};