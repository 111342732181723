import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import Swal from "sweetalert2";

export const HandleSchemaDelete = async (setDeletingSchema) => {

  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  const classList = ['POST', 'Comment', 'Response', 'Accounts', 'Records', 'Logs', 'Persons'];

  let color = "";

  if (localStorage.theme === "dark") {
    color = "#111827";
  } else {
    color = "";
  }

  setDeletingSchema(true);

  Swal.fire({
    background: color,
    html:
      `
        <label class= "text-gray-800 dark:text-gray-100 font-semibold text-2xl">Remove Response</label>
        <form class="border p-5 rounded-lg mt-3 border-red-500">
          <p class="text-gray-800 dark:text-gray-100">This action will delete all schema.</p>
        </form>
      `,
    showCancelButton: true,
    confirmButtonText: 'Remove',
    showLoaderOnConfirm: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'bg-red-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-red-500 w-36',
      cancelButton: 'bg-blue-600 text-gray-100 mr-2 p-2 rounded-lg hover:bg-blue-500 w-20'
    },
    preConfirm: async () => {
      for (const className of classList) {
        await client.schema
          .classDeleter()
          .withClassName(className)
          .do()
          .then(res => {
            toastEffect("success", `Schema ${className} deleted successfully.`);
          })
          .catch(err => {
            console.error(err);
          });
      }
      setDeletingSchema(false);
    },
    allowOutsideClick: () => !Swal.isLoading()
  });

  // for (const className of classList) {
  //   await client.schema
  //     .classDeleter()
  //     .withClassName(className)
  //     .do()
  //     .then(res => {
  //       toastEffect("success", `Schema ${className} deleted successfully.`);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // }
  // setDeletingSchema(false);
};