import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { Log } from "../functions/Log";

const Header = ({ accountData }) => {

  // Account token
  const token = JSON.parse(localStorage.getItem("account"));

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("");

  const toggleProfileMenu = () => {
    if (isOpenMenu) {
      setIsOpenMenu(false);
    } else {
      setIsOpenMenu(true);
    }
  };

  const handleTheme = () => {
    if (localStorage.theme === "dark") {
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
    } else {
      localStorage.theme = "dark";
      document.documentElement.classList.add("dark");
    }
    setCurrentTheme(localStorage.theme);
  };

  const handleLogout = () => {
    localStorage.removeItem("account");
    Log(token, "Logout");
  };

  const handleNavigatePage = (page) => {
    window.location.href = `/${page}`;
  };

  return (
    <nav className="fixed top-0 z-50 w-full bg-gray-100 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
              <span className="sr-only">Open sidebar</span>
              <FontAwesomeIcon className="h-5 w-5" icon={faBars} />
            </button>

            <Link to="/" className="flex ml-2 md:mr-24">
              <img src="favicon.ico" className="h-8 mr-3" alt="FlowBite Logo" />
              <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">Data Entry</span>
            </Link>
          </div>

          <div className="flex items-center">
            <div className="flex items-center ml-3">

              {accountData.length !== 0 ? (
                <div>
                  <button onClick={toggleProfileMenu} type="button" className="flex text-sm dark:bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                    <span className="sr-only">Open user menu</span>
                    <img className="w-8 h-8 rounded-full" src="default.png" alt="user photo" />
                  </button>
                </div>
              ) : (
                <div className="flex text-sm dark:bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                  <Link to="/" className="dark:text-gray-100 font-semibold mr-5 text-base">Log in</Link>
                </div>
              )}

              <div onClick={handleTheme} type="button" className="cursor-pointer flex text-sm" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                <span className="sr-only">Open user menu</span>
                <FontAwesomeIcon title={currentTheme === "dark" ? "Light" : "Dark"} className="w-5 h-5 p-2 ml-2 rounded-full bg-gray-300 dark:bg-gray-600 hover:text-gray-700 dark:hover:text-gray-300 text-gray-500 dark:text-gray-400" icon={currentTheme === "dark" ? faSun : faMoon} />
              </div>

              {isOpenMenu && (
                <div className="fixed z-50 top-9 right-16 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-user">
                  <div className="px-4 py-3" role="none">
                    <b className="text-sm text-gray-900 dark:text-white" role="none">
                      {accountData?.name}
                    </b>
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                      {accountData?.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    {/* <li>
                      <h1 className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">
                        Settings
                      </h1>
                    </li> */}
                    <li>
                      <h1 onClick={() => handleNavigatePage("data-entry")} className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">
                        Data Entry
                      </h1>
                    </li>
                    <li>
                      <h1 onClick={handleLogout} className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                        Log Out
                      </h1>
                    </li>
                  </ul>
                </div>
              )}

            </div>

          </div>
        </div>
      </div>
    </nav >
  );
};

export default Header;