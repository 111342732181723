import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faComment, faComments, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { HandleUpdateResponse } from "../functions/HandleUpdateResponse";
import { HandleRemoveComment } from "../functions/HandleRemoveComment";
import { HandleUpdateComment } from "../functions/HandleUpdateComment";
import { HandleResponse } from "../functions/HandleResponse";
import { HandleComment } from "../functions/HandleComment";
import { HandleRemoveResponse } from "../functions/HandleRemoveResponse";
import { Link } from "react-router-dom";
import weaviate from "weaviate-client";
import LeftSide from "./LeftSide";
import { Retriever } from "../functions/Retriever ";

const Responses = ({ accountData }) => {

  const location = useLocation();
  const textAreaComment = useRef(null);
  const textAreaResponse = useRef(null);
  const bottomRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const postID = params.get("id");

  // Account token
  const token = JSON.parse(localStorage.getItem("account"));

  const [POST, setPOST] = useState([]);
  const [comments, setComments] = useState([]);
  const [responses, setResponses] = useState([]);
  const [isResponseMenuOpen, setIsResponseMenuOpen] = useState(Array(responses.length).fill(false));
  const [isMenuOpen, setIsMenuOpen] = useState(Array(comments.length).fill(false));
  const [responseField, setResponseField] = useState(Array(comments.length).fill(false));
  const [commentField, setCommentField] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [reponseValue, setResponseValue] = useState("");
  const [refreshData, setRefreshData] = useState(0);
  const [savingComment, setSavingComment] = useState(false);
  const [savingResponse, setSavingResponse] = useState(false);
  const [commentName, setCommentName] = useState("");
  const [commentProfile, setCommentProfile] = useState("");
  const [responseName, setResponseName] = useState("");
  const [responseProfile, setResponseProfile] = useState("");
  const [personData, setPersonData] = useState("");
  const [persons, setPersons] = useState([]);

  // check is token existed
  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  // Weaviate connection config
  const client = weaviate.client({
    scheme: process.env.REACT_APP_SCHEME,
    host: process.env.REACT_APP_WEAVIATE_ENPOINT,
  });

  // Text area row effect
  const resizeTextArea = (refData) => {
    refData.current.style.height = "auto";
    if (refData.current.scrollHeight >= 200) {
      refData.current.style.height = 200 + "px";
    } else {
      refData.current.style.height = refData.current.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (textAreaComment?.current === null) {
      return;
    }
    resizeTextArea(textAreaComment);
  }, [commentValue]);

  useEffect(() => {
    if (textAreaResponse?.current === null) {
      return;
    }
    resizeTextArea(textAreaResponse);
  }, [reponseValue]);

  useEffect(() => {
    if (!accountData?.flast_id) { return; }

    const getAllData = async () => {

      await client.data
        .getterById()
        .withClassName('POST')
        .withId(postID)
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ONE)// ONE|QUORUM|ALL ---> https://weaviate.io/developers/weaviate/concepts/replication-architecture/consistency
        .do()
        .then(res => {
          // console.log("Specific Post", res);
          setPOST(res);
        })
        .catch(err => {
          console.error(err);
        });

      const resPromisePersons = Retriever("Persons", 100);
      resPromisePersons.then(res => {
        setPersons(res);// This will log the resolved value of the Promise
      }).catch(err => {
        console.error(err); // Handle errors here
      });

      const resPromiseComment = Retriever("Comment", 100);
      resPromiseComment.then(res => {
        const filteredObjects = res
          .filter(obj => obj.properties.writesFor.some(w => w.href.includes(`/v1/objects/POST/${postID}`)))
          .sort((a, b) => a.creationTimeUnix - b.creationTimeUnix);
        setComments(filteredObjects);
      }).catch(err => {
        console.error(err); // Handle errors here
      });

      const resPromiseResponse = Retriever("Response", 100);
      resPromiseResponse.then(res => {
        setResponses(res);
      }).catch(err => {
        console.error(err); // Handle errors here
      });

      // await client.data
      //   .getter()
      //   .withClassName('Persons')
      //   // .withLimit(2)
      //   .do()
      //   .then(res => {
      //     setPersons(res.objects);
      //     // console.log("Persons", res);
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   });

      // await client.data
      //   .getter()
      //   .withClassName('Comment')
      //   // .withLimit(2)
      //   .do()
      //   .then(res => {
      //     // const filteredObjects = res.objects.filter(obj => obj.properties.writesFor.some(w => w.href.includes(`/v1/objects/POST/${postID}`)));
      //     const filteredObjects = res.objects
      //       .filter(obj => obj.properties.writesFor.some(w => w.href.includes(`/v1/objects/POST/${postID}`)))
      //       .sort((a, b) => a.creationTimeUnix - b.creationTimeUnix);
      //     setComments(filteredObjects);
      //     // console.log("Comments", filteredObjects);
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   });

      // await client.data
      //   .getter()
      //   .withClassName('Response')
      //   .do()
      //   .then(res => {
      //     setResponses(res.objects);
      //     // console.log("Reponses", res);
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   });

    };
    getAllData();
  }, [refreshData, accountData]);

  const toggleCommentMenu = (idx) => {
    const newIsMenuOpen = Array(comments.length).fill(false);
    if (isMenuOpen[idx]) {
      newIsMenuOpen[idx] = false;
    } else {
      newIsMenuOpen[idx] = true;
    }
    setIsMenuOpen(newIsMenuOpen);
  };

  const toggleResponseField = (idx) => {
    const newIsMenuOpen = Array(comments.length).fill(false);
    if (responseField[idx]) {
      newIsMenuOpen[idx] = false;
    } else {
      newIsMenuOpen[idx] = true;
    }
    setResponseField(newIsMenuOpen);
  };

  const toggleResponseMenu = (idx, id) => {
    const newIsMenuOpen = Array(responses.length).fill(false);
    if (isResponseMenuOpen[id]) {
      newIsMenuOpen[id] = false;
    } else {
      newIsMenuOpen[id] = true;
    }
    setIsResponseMenuOpen(newIsMenuOpen);
  };

  const handleAddComment = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    if (commentField) {
      setCommentField(false);
    } else {
      setCommentField(true);
    }
  };

  return (
    <>
      <LeftSide accountData={accountData} />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <section className="bg-white dark:bg-gray-900 py-8 lg:py-16">
            <div className="max-w-2xl mx-auto px-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg lg:text-xl font-bold text-gray-900 dark:text-white">Post by - <small>{POST?.properties?.fb_name}</small></h2>
                {/* <button className="float-right text-lg lg:text-xl font-bold text-gray-900 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 p-1 rounded-md">
                  <FontAwesomeIcon icon={faComment} /> <small className="text-sm">Add Comment</small>
                </button> */}
                {/* <div className="float-right text-lg lg:text-sm font-bold text-gray-900 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 rounded-md">
                  Total (100)
                </div> */}
              </div>
              {/* <form className="mb-6">
                <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                  <label htmlFor="comment" className="sr-only">Your comment</label>
                  <textarea id="comment" rows="6"
                    className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                    placeholder="Write a comment..." required></textarea>
                </div>
                <button type="submit"
                  className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                  Post comment
                </button>
              </form> */}
              <p className="text-gray-600 dark:text-gray-200 font-semibold whitespace-pre-line border p-5 rounded-lg">
                {POST?.properties?.fb_post}
              </p>

              <button onClick={handleAddComment} className="float-right mt-2 text-lg lg:text-xl font-bold text-gray-500 dark:text-white focus:ring-1 focus:ring-blue-200 dark:focus:ring-blue-900 p-1 rounded-md">
                <FontAwesomeIcon icon={faComment} /> <small className="text-sm">Add Comment</small>
              </button>

              {comments?.map((data, idx) => {

                const filteredResponses = responses
                  .filter(obj => obj.properties.writesFor.some(w => w.href.includes(`/v1/objects/Comment/${data.id}`)))
                  .sort((a, b) => a.creationTimeUnix - b.creationTimeUnix);

                // console.log("filteredResponses", filteredResponses);
                return (
                  <div key={idx}>
                    {/* Comment filed */}
                    <article className={`${idx !== 0 && `border-t border-gray-200`} p-6 mt-6 mb-1 text-base bg-white dark:border-gray-700 dark:bg-gray-900`}>

                      <footer className="flex justify-between items-center mb-2">

                        <div className="flex items-center inline-flex">
                          <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img
                              className="mr-2 w-6 h-6 rounded-full"
                              src="default.png"
                              alt="Michael Gough" /><Link className="text-blue-500 hover:text-blue-400 hover:underline" to={data.properties.fb_profile} target="_blank">{data.properties.fb_name}</Link></p>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            <small>Comment</small>
                          </p>
                        </div>

                        <div className="inline-flex relative"> {/* Add the "relative" class */}
                          <button onClick={() => toggleCommentMenu(idx)} className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-400 bg-white rounded-lg hover:bg-gray-100 focus:ring-1 focus:outline-none focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            type="button">
                            <FontAwesomeIcon className="h-5 w-5" icon={faEllipsis} />
                            <span className="sr-only">Comment settings</span>
                          </button>

                          {isMenuOpen[idx] && (
                            <div className="absolute z-10 top-full right-0 mt-1 w-36 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"> {/* Use the "absolute" class and the "top-full" and "left-0" properties to position the dropdown div */}
                              <div className="text-center px-4 py-1" role="none">
                                <b className="text-sm text-gray-900 dark:text-white" role="none">
                                  Edit Comment
                                </b>
                              </div>
                              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                                <li>
                                  <h1
                                    onClick={
                                      () => HandleUpdateComment(
                                        setIsMenuOpen,
                                        comments,
                                        client,
                                        setRefreshData,
                                        data.properties.fb_name,
                                        data.properties.fb_profile,
                                        data.properties.fb_comment,
                                        data.id,
                                        accountData
                                      )
                                    }
                                    className="cursor-pointer block py-1 px-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    Update
                                  </h1>
                                </li>
                                <li>
                                  <h1
                                    onClick={
                                      () => HandleRemoveComment(
                                        setIsMenuOpen,
                                        comments,
                                        client,
                                        setComments,
                                        data.id,
                                        filteredResponses.length,
                                        accountData
                                      )
                                    }
                                    className="cursor-pointer block py-1 px-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    Remove
                                  </h1>
                                </li>
                              </ul>
                            </div>
                          )}

                        </div>
                      </footer>


                      <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
                        {data.properties.fb_comment}
                      </p>
                      <div className="flex items-center mt-2 space-x-4">
                        <button onClick={() => toggleResponseField(idx)} type="button" className="flex items-center text-xs text-gray-500 dark:text-gray-400 focus:ring-1 focus:ring-blue-200 dark:focus:ring-blue-900 p-1 rounded-md">
                          {/* <FontAwesomeIcon className="mr-1" icon={faComments} /> */}
                          <FontAwesomeIcon className="mr-1" icon={faComments} /> Add Response
                        </button>
                      </div>
                      {responseField[idx] && (
                        <form
                          onSubmit={
                            (e) => HandleResponse(
                              e,
                              setSavingResponse,
                              client,
                              setResponses,
                              setResponseValue,
                              setResponseName,
                              setResponseProfile,
                              accountData,
                              setPersonData
                            )
                          }
                          className="border p-5 rounded-lg mt-3"
                        >
                          <p className="text-gray-100 mb-5 text-xl font-semibold">Add Response</p>
                          <input hidden id="comment_id" defaultValue={data.id} />
                          <div className="grid gap-6 md:grid-cols-1">
                            <input
                              value={personData}
                              onInput={(e) => setPersonData(e.target.value)}
                              placeholder="Select Person"
                              list="countries"
                              id="facebook_post_person"
                              name="countries"
                              required
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            />
                            <datalist id="countries">
                              {persons.map((data, idx) => {
                                return (
                                  <option key={idx} value={`${data.properties.fb_name} - ${data.properties.fb_profile}`} />
                                );
                              })}
                            </datalist>

                            {/* <div className="grid gap-6 mb-6 md:grid-cols-2">
                              <div>
                                <input value={responseName} onChange={(e) => setResponseName(e.target.value)} id="facebook_response_name" type="text" placeholder="FB name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                              </div>
                              <div>
                                <input value={responseProfile} onChange={(e) => setResponseProfile(e.target.value)} id="facebook_response_profile" type="text" placeholder="FB profile" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                              </div>
                            </div> */}

                            <div className="relative mb-6">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <FontAwesomeIcon className="text-gray-400" icon={faComments} />
                              </div>
                              <textarea
                                ref={textAreaResponse}
                                onChange={(e) => setResponseValue(e.target.value)}
                                value={reponseValue}
                                rows="1"
                                type="text"
                                name="facebook_response"
                                id="facebook_response"
                                placeholder="Add facebook response"
                                required
                                className="scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                            </div>
                          </div>
                          <button disabled={savingResponse ? true : false} type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <FontAwesomeIcon spin={savingResponse ? true : false} icon={faArrowsRotate} size="1x" /> {savingResponse ? "Saving..." : "Save Response"}
                          </button>
                        </form>
                      )}
                    </article>

                    {/* Response field */}
                    {filteredResponses.map((data, idx) => {
                      return (
                        <article key={idx} className="p-6 mb-0 ml-6 lg:ml-12 text-base bg-white rounded-lg dark:bg-gray-900">
                          <footer className="flex justify-between items-center mb-2">

                            <div className="flex items-center inline-flex">
                              <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                                <img
                                  className="mr-2 w-6 h-6 rounded-full"
                                  src="default.png"
                                  alt="Michael Gough" /><Link className="text-blue-500 hover:text-blue-400 hover:underline" to={data.properties.fb_profile} target="_blank">{data.properties.fb_name}</Link></p>
                              <p className="text-sm text-gray-600 dark:text-gray-400">
                                <small>Response</small>
                              </p>
                            </div>

                            <div className="inline-flex relative"> {/* Add the "relative" class */}
                              <button onClick={() => toggleResponseMenu(idx, data.id)} className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-400 bg-white rounded-lg hover:bg-gray-100 focus:ring-1 focus:outline-none focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                type="button">
                                <FontAwesomeIcon className="h-5 w-5" icon={faEllipsis} />
                                <span className="sr-only">Comment settings</span>
                              </button>

                              {isResponseMenuOpen[data.id] && (
                                <div className="absolute z-10 top-full right-0 mt-1 w-36 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"> {/* Use the "absolute" class and the "top-full" and "left-0" properties to position the dropdown div */}
                                  <div className="text-center px-4 py-1" role="none">
                                    <b className="text-sm text-gray-900 dark:text-white" role="none">
                                      Edit Response
                                    </b>
                                  </div>
                                  <ul className="py-1 text-sm text-gray-700 dark:text-gray-200"
                                    aria-labelledby="dropdownMenuIconHorizontalButton">
                                    <li>
                                      <h1
                                        onClick={
                                          () => HandleUpdateResponse(
                                            setIsResponseMenuOpen,
                                            responses,
                                            client,
                                            data.id,
                                            data.properties.fb_profile,
                                            data.properties.fb_name,
                                            data.properties.fb_response,
                                            setRefreshData,
                                            accountData
                                          )}
                                        className="cursor-pointer block py-1 px-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                        Update
                                      </h1>
                                    </li>
                                    <li>
                                      <h1
                                        onClick={
                                          () => HandleRemoveResponse(
                                            setIsResponseMenuOpen,
                                            responses,
                                            setResponses,
                                            client,
                                            data.id,
                                            accountData
                                          )
                                        }
                                        className="cursor-pointer block py-1 px-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                      >
                                        Remove
                                      </h1>
                                    </li>
                                  </ul>
                                </div>
                              )}

                            </div>
                          </footer>
                          <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
                            {data.properties.fb_response}
                          </p>
                          {/* <div className="flex items-center mt-4 space-x-4">
                          <button type="button"
                            className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400">
                            <svg aria-hidden="true" className="mr-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
                            Reply
                          </button>
                        </div> */}
                        </article>
                      );
                    })}
                  </div>
                );
              })}

              {commentField && (
                <form
                  onSubmit={
                    (e) => HandleComment(
                      e,
                      client,
                      setSavingComment,
                      postID,
                      setComments,
                      setCommentValue,
                      setCommentName,
                      setCommentProfile,
                      accountData,
                      setPersonData
                    )
                  }
                  className={`border p-5 rounded-lg ${!comments.length && "mt-16"}`}
                >
                  <p className="dark:text-gray-100 mb-5 text-xl font-semibold">Add Comment</p>

                  <div className="grid gap-6 md:grid-cols-1">
                    <input
                      value={personData}
                      onInput={(e) => setPersonData(e.target.value)}
                      placeholder="Select Person"
                      list="countries"
                      id="facebook_post_person"
                      name="countries"
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <datalist id="countries">
                      {persons.map((data, idx) => {
                        return (
                          <option key={idx} value={`${data.properties.fb_name} - ${data.properties.fb_profile}`} />
                        );
                      })}
                    </datalist>

                    {/* <div className="grid gap-6 mb-6 md:grid-cols-2">
                      <div>
                        <input value={commentName} onChange={(e) => setCommentName(e.target.value)} id="facebook_comment_name" type="text" placeholder="FB name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                      </div>
                      <div>
                        <input value={commentProfile} onChange={(e) => setCommentProfile(e.target.value)} id="facebook_comment_profile" type="text" placeholder="FB profile" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                      </div>
                    </div> */}

                    <div className="relative mb-6">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon className="text-gray-400" icon={faComment} />
                      </div>
                      <textarea
                        ref={textAreaComment}
                        onChange={(e) => setCommentValue(e.target.value)}
                        value={commentValue}
                        rows="1"
                        type="text"
                        name="facebook_comment"
                        id="facebook_comment"
                        placeholder="Add facebook comment"
                        required
                        className="scrollbar-thumb-slate-400 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <button disabled={savingComment ? true : false} type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <FontAwesomeIcon spin={savingComment ? true : false} icon={faArrowsRotate} size="1x" /> {savingComment ? "Saving..." : "Save Comment"}
                  </button>
                </form>
              )}

            </div>
          </section>
        </div>
      </div>
      <div ref={bottomRef} className="h-auto"></div>
    </>
  );
};

export default Responses;