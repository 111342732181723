import { toastEffect } from "../ToastEffect";
import weaviate from "weaviate-client";
import { Record } from "./Record";
import { Retriever } from "./Retriever ";

export const HandleNewPerson = async (
  e,
  client,
  accountData,
  setSavingPerson,
  setPersonName,
  setPersonProfile,
  setPersons
) => {
  e.preventDefault();
  e.stopPropagation();

  if (!accountData?.flast_id) { return; }

  const fb_name = e.target.elements.facebook_person_name.value;
  const fb_profile = e.target.elements.facebook_person_profile.value;

  if (fb_name === "" || fb_profile === "") {
    return;
  }

  setSavingPerson(true);

  const resPromise = Retriever("Persons", 100);
  resPromise.then(async res => {

    const result = res.find(obj => obj.properties.fb_profile === fb_profile);
    const profileIDExist = { exists: !!result, data: result };

    if (profileIDExist.exists) {
      setSavingPerson(false);
      toastEffect("error", "Facebook person already exist!");
    } else {
      await client.data
        .creator()
        .withClassName('Persons')
        .withProperties({
          'fb_name': fb_name,
          'fb_profile': fb_profile
        })
        .withConsistencyLevel(weaviate.replication.ConsistencyLevel.ALL)  // default QUORUM
        .do()
        .then(async res => {

          const newPerson = {
            class: "Persons",
            creationTimeUnix: Date.now(),
            id: res.id,
            lastUpdateTimeUnix: Date.now(),
            properties: {
              fb_name: fb_name,
              fb_profile: fb_profile
            },
            vectorWeights: null
          };

          await Record(client, accountData, res.class, res.id, "insert");
          setPersonName("");
          setPersonProfile("");
          setSavingPerson(false);
          setPersons(prevData => [...prevData, newPerson]);
          toastEffect("success", "Person added successfully.");
        })
        .catch(err => {
          console.error(err);
        });
    }

  }).catch(err => {
    console.error(err); // Handle errors here
  });

  // await client.data
  //   .getter()
  //   .withClassName('Persons')
  //   .do()
  //   .then(async res => {
  //
  //   })
  //   .catch(err => {
  //     console.error(err);
  //   });
};